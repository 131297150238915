<template>
	<div class="wrap">
		<div class="main">
			<div class="header">
				<h1 class="title">模板中心</h1>
				<div class="input-wrap">
					<el-input v-model="query.name" @input="queryName" clearable placeholder="搜索" size="large">
						<template #prefix>
							<el-icon class="el-input__icon">
								<search />
							</el-icon>
						</template>
					</el-input>
				</div>
			</div>
			<div class="various-types">
				<ul>
					<li v-for="item in variousTypes" :key="item.id">
						<div class="title">
							{{ item.title }}：
						</div>
						<ul class="check-group">
							<li class="check-item" @click="seleckAllCheck(item)" :class="{ active: item.isSelect }">不限
							</li>
							<li class="check-item" v-for="item_ in item.radioList" :key="item_.id" :value="item_.code"
								@click="selectCheck(item_, item)" :class="{ active: item_.isSelect }">
								{{ item_.name }}
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<ul class="radio-list">
				<li class="radio-item" :class="{ active: sortValue === 'new' }" @click="sortValue = 'new'">最新</li>
				<li class="radio-item" :class="{ active: sortValue === 'view' }" @click="sortValue = 'view'">浏览量</li>
			</ul>
			<ul class="product-list">
				<li v-for="item in productList" :key="item.id">
					<div class="top"><img :src="OSSURL + item.thumbnail + ctbImgClipQuery" alt=""
							@click="toDetail(item)"></div>
					<div class="details">
						<div class="d-header">
							<div class="d-title" @click="toDetail(item)">
								<span v-if="item.name.length <= 22">{{ item.name }}</span>
								<span v-else>{{ item.name.slice(0, 19) }}...</span>
							</div>
							<div class="sell-type">
								<span v-if="item.sellType === Constant.CtbSellFree">免费</span>
								<span v-else-if="item.sellType === Constant.CtbSellCash">￥{{ item.price }}</span>
							</div>
						</div>
						<div class="footer">
							<div class="left">
								<img class="avator" :src="OSSURL + item.author.avatar" alt=""
									@click="router.push({ path: '/personPage', query: { id: item.author.userId } })">
								<span class="author-name" v-text="item.author.nickName"
									@click="toAuther(router.resolve({ path: '/personPage', query: { id: item.author.userId } }))"></span>
							</div>
							<div class="right">
								<div>
									<img src="@/assets/icons/eye.png" alt="" srcset=""
										style="width:12px;margin-right: 3px;">
									<!-- <el-icon style="margin-right: 3px;">
										<PictureRounded />
									</el-icon> -->
									<span v-text="item.viewNum"></span>
								</div>
								<div>
									<!-- <el-icon style="margin-right: 3px;">
										<ChatLineRound />
									</el-icon> -->
									<img src="@/assets/icons/comment.png" alt="" srcset=""
										style="width:12px;margin-right: 3px;">
									<span v-text="item.commentNum"></span>
								</div>
								<div class="like" @click="switchLike(item)">
									<img src="@/assets/icons/heart.png" alt="" srcset=""
										style="width:12px;cursor: pointer;" v-show="item.likeStatus !== 1">
									<img src="@/assets/icons/heart-r.png" alt="" srcset=""
										style="width:12px;cursor: pointer;" v-show="item.likeStatus === 1">
									<span v-text="item.likeNum"></span>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<div style="display: flex;justify-content: center;">
				<NoData v-show="productList.length === 0" :text="'暂无数据'"
					:imgStyle="{ 'width': '300px', 'height': '300px', 'margin': '0 auto', 'text-align': 'center' }"
					:textStyle="{ 'font-size': '22px', 'margin-top': '30px', 'color': '#fff' }" />
			</div>
			<Page v-show="productList.length > 0" :current-page="query.page" :page-size="query.size"
				:total-items="total" @current="handleCurrentChange" @size="handleSizeChange" />
		</div>
	</div>
</template>

<script setup>
import { isUserLoggedIn } from '@/router'
import { useStore } from 'vuex'
import NoData from '@/components/common/noData';
import { ctbImgClipQuery } from '@/constant/oss'
import { OSSURL } from '@/util/file';
import api from '@/api/api';
import constant from '@/constant/constant';
import { onMounted, ref, reactive, toRaw, watch } from 'vue';
import Page from '@/components/common/page';
import { useRouter,useRoute } from 'vue-router';
import Constant from '@/constant/constant';
const route = useRoute();
const router = useRouter();
const variousTypes = ref([])
const sellType = ref('');
const sortValue = ref('new');
const productList = ref([]);
const total = ref(0);
const query = reactive({
	page: constant.PaginationDefaultPage,
	size: constant.PaginationDefaultSize,
	name: ''
})
const store = useStore();
let timer = null;
function queryName() {
	clearTimeout(timer);
	timer = setTimeout(() => {
		getProductList()
	}, 300)
}
function toDetail({ ctbId }) {
	router.push({ path: `/template/dtl/${ctbId}` })
}
async function getOptionsList() {
	try {
		//风格
		const stylePromise = api.DictCodeList({ code: constant.CtbProjTagFg })
		//面积
		const areaPromise = api.DictCodeList({ code: constant.CtbProjTagMj })
		//价格
		const pricePromise = api.DictCodeList({ code: constant.DictCodeSellType })
		//场景
		const scenePromise = api.DictCodeList({ code: constant.CtbProjTagCj })
		//类型
		const typePromise = api.DictCodeList({ code: constant.CtbProjTagLx })
		const res = await Promise.all([stylePromise, areaPromise, pricePromise, scenePromise, typePromise])
		const arr_ = [
			{
				id: 1,
				title: '风格',
				tagType: constant.CtbProjTagFg,
				radioList: [],
				value: [],
				isSelect: true
			},
			{
				id: 2,
				title: '面积',
				tagType: constant.CtbProjTagMj,
				radioList: [],
				value: [],
				isSelect: true
			},
			{
				id: 3,
				title: '价格',
				tagType: constant.DictCodeSellType,
				radioList: [],
				value: [],
				isSelect: true
			},
			{
				id: 4,
				title: '场景',
				tagType: constant.CtbProjTagCj,
				radioList: [],
				value: [],
				isSelect: true
			},
			{
				id: 5,
				title: '类型',
				tagType: constant.CtbProjTagLx,
				radioList: [],
				value: [],
				isSelect: true
			}
		]
		const arr = res.map((item, index) => {
			item.isSelect = false;
			item.result.forEach((item_) => {
				item_.isSelect = false;
			})
			arr_[index].radioList = item.result;
			return item.result;
		})
		variousTypes.value = arr_;
		getProductList();
	} catch (error) {
		console.log(error);
	}
}
async function getProductList() {
	try {
		const variousTypesRaw = JSON.parse(JSON.stringify(toRaw(variousTypes.value)));
		variousTypesRaw.splice(2, 1)
		const tagList = variousTypesRaw.map(item => {
			return {
				tagType: item.tagType,
				codes: item.radioList.filter(item => item.isSelect).map(item_ => item_.code) || []

			}
		})
		const params = {
			page: query.page,
			size: query.size,
			sellType: sellType.value,
			sortField: sortValue.value,
			tagList,
			name: query.name,
			userId:store?.state?.user?.userView?.id || 0
		}		
		const res = await api.productList(params);
		total.value = res.total;
		productList.value = res.result || [];
	} catch (error) {
		console.log(error);
	}
}
function handleCurrentChange(val) {
	query.page = val
	getProductList()
}
function handleSizeChange(val) {
	query.size = val
	getProductList()
}
function selectCheck(item, father) {
	//价格筛选单独处理
	if (father.tagType === constant.DictCodeSellType) {
		father.radioList.forEach((item_) => {
			item_.isSelect = false;
		})
		item.isSelect = true;
		father.isSelect = false;
		sellType.value = item.code;

	} else {
		item.isSelect = !item.isSelect;
		if (item.isSelect) {
			father.isSelect = false;
		}
	}
	getProductList();
}
function seleckAllCheck(father) {
	//价格筛选单独处理
	if (father.tagType === constant.DictCodeSellType) {
		sellType.value = '';
		father.radioList.forEach((item_) => {
			item_.isSelect = false;
		})
		father.isSelect = true;
	} else {
		father.isSelect = true;
		father.radioList.forEach((item) => {
			item.isSelect = false;
		})
	}
	getProductList();
}
function toAuther(params) {
	window.open(params.href, '_blank');
}
async function switchLike(item) {
	try {
		if (!isUserLoggedIn()) {
            handleLogin();
            return;
        }
		const likeStatus = item.likeStatus === 1 ? 2 : 1
		await api.likeProgramme({ bizId: item.ctbId, status: likeStatus })
		item.likeStatus = likeStatus;
		switch (item.likeStatus) {
			case 1:
				item.likeNum += 1
				break;
			case 2:
				item.likeNum -= 1
				break;
		}
	} catch (error) {
		console.log(error);
	}
}
async function handleLogin() {
    await store.dispatch('getWeChatSetting');
    store.commit('SET_LOGIN_MODAL_VISIBLE', {
        visible: true,
        type: 'wxLogin',
        path: route.fullPath,

    });
}
onMounted(() => {
	getOptionsList()
})
watch(sortValue, () => {
	getProductList()
})
</script>

<style lang="scss" scoped>
.wrap {
	width: 100%;
	background-color: #161824;

	.main {
		width: 1400px;
		min-height: 966px;
		margin: 0px auto;
		padding: 24px 0;

		.header {
			height: 100px;
			display: flex;
			align-items: center;

			>.title {
				padding-left: 20px;
				color: #fff;
				margin-right: 275px;
			}

			>.input-wrap {
				width: 500px;
			}
		}

		.various-types {
			padding: 32px;
			font-size: 14px;
			font-weight: 600;
			color: #FDFDFD;
			margin-bottom: 30px;
			background: #2B2E42;
			border-radius: 20px;

			ul {
				li {
					display: flex;
					align-items: center;

					>.title {
						width: 50px;
						font-size: 16px;
					}

					.check-group {
						display: flex;
						flex-wrap: wrap;
						align-items: center;

						.check-item {
							cursor: pointer;
							margin-right: 10px;
							height: 28px;
							background: #383B48;
							border-radius: 7px;
							line-height: 28px;
							text-align: center;
							padding: 8px;
							color: #FDFDFD;
							margin-bottom: 12px;

							&.active {
								background: #47313f;
								color: #AE3B34;

							}
						}
					}
				}
			}
		}

		.radio-list {
			margin-bottom: 30px;
			display: flex;

			>.radio-item {
				height: 36px;
				line-height: 36px;
				padding: 0 16px;
				margin-right: 10px;
				border-radius: 8px;
				background-color: #2b2f42;
				color: #fff;
				cursor: pointer;

				&.active {
					background-color: #b13a37;
				}
			}
		}

		.product-list {
			display: flex;
			flex-wrap: wrap;

			li:nth-child(3n) {
				margin-right: 0 !important;
			}

			li {
				width: 445px;
				margin-bottom: 24px;
				margin-right: 30px;

				.top {
					border-radius: 12px 12px 0px 0px;
					overflow: hidden;

					>img {
						width: 100%;
						cursor: pointer;
					}
				}

				.details {
					padding: 16px 20px;
					border-radius: 0 0 12px 12px;

					background-color: rgb(255, 255, 255);
					box-shadow: rgba(96, 101, 108, 0.08) 4px 4px 24px 0px;

					>.d-header {
						display: flex;
						justify-content: space-between;
						margin-bottom: 6px;

						>.d-title {
							font-weight: 400;
							font-size: 16px;
							color: rgb(51, 51, 51);
							cursor: pointer;

							&:hover {
								color: #7bc1ff;
							}
						}

						>.sell-type {
							height: 30px;
							line-height: 30px;
							background-color: #b13a37;
							color: #fff;
							text-align: center;
							padding: 0 12px;
							border-radius: 8px;
						}
					}


					.footer {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.left {
							display: flex;
							align-items: center;

							.avator {
								width: 30px;
								height: 30px;
								border-radius: 50%;
								margin-right: 6px;
								cursor: pointer;
							}

							.author-name {
								font-size: 12px;
								color: rgb(102, 102, 102);
								cursor: pointer;

								&:hover {
									color: #7bc1ff;
								}
							}
						}

						.right {
							display: flex;

							>div {
								font-size: 12px;
								margin-right: 8px;
								display: flex;
								align-items: center;
								color: rgb(147, 152, 159);

								&.like {
									cursor: pointer;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>