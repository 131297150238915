<template>
	<div class="home-footer">
		<!-- <div class="footer-info">
				<div class="footer-left">
					<div class="consult">
						<p>企业版购买咨询</p>
						<div><el-icon><Phone /></el-icon>0314-2539999</div>
						<span>TIME : 8:30-21:30</span>
					</div>
					<div class="cooperation">
						<p>合作</p>
						<ul>
							<li @click="router.push('/upgrades')">商务合作</li>
							<li @click="router.push('/upgrades')">开放平台</li>
							<li @click="router.push('/upgrades')">商家后台</li>
							<li @click="router.push('/upgrades')">客户服务</li>
							<li @click="router.push('/upgrades')">服务代理加盟</li>
						</ul>
					</div>
					
				</div>
				<div class="footer-right">
					<ul v-for="arr in productLinks">
						<li v-for="item in arr" @click="handleLinkTo(item)">
							{{ item.name }}
						</li>
					</ul>
				</div>
			</div> -->
		<div class="footer-info">
			<ul class="list">
				<li class="first">
					<el-popover placement="bottom" :width="200" trigger="hover">
						<div class="imgBox1">
							<img :src="OSSURL + 'home/sell.png'" alt="">
						</div>
						<template #reference>
							<div class="content">
								<img src="@/assets/icons/phone-icon.png" alt="">
								<a class="default-link">0314-2539999</a>
								<span class="line">|</span>
							</div>
						</template>
					</el-popover>

				</li>
				<li v-for="(item, index) in footerList" :key="item.name">
					<el-link :underline="false" :href="item.link" class="default-link">{{item.name}}</el-link>
					<span class="line" v-if="index !== footerList.length - 1">|</span>
				</li>
			</ul>
      <div>
        <p>地址：承德市双滦区第二迎宾大道金宝商业广场G座</p>
      </div>
		</div>

		<div class="footer-license">
			<p>
				© 2014-2025 TUBUSI 图布斯科技集团
				<el-link class="footer-link" :underline="false" href="https://beian.miit.gov.cn/#/Integrated/index"
					target="_blank">冀ICP备2024053175号-3</el-link>
				<el-link class="footer-link" :underline="false" href="https://beian.mps.gov.cn/#/query/webSearch"
					target="_blank"><img class="police" src="@/assets/emblem.png" alt="">冀公网安备13080302000110</el-link>
			</p>
      <el-link :underline="false" :href="licenceImg" target="_blank">图布斯距百年企业还有 {{ dateObj?.year }} 年 {{
				dateObj?.month }} 月 {{ dateObj?.day }} 天</el-link>
		</div>
	</div>
</template>

<script setup>
import { OSSURL } from '@/util/file';
import { onMounted, ref } from 'vue';
import { productLinks, footerList } from './constant';
import { getDateSpacing } from '@/util/date';
import { useRouter } from 'vue-router';
import licenceImg from '@/assets/images/licence.jpg';
const router = useRouter();

const dateObj = ref({});
onMounted(() => {
	getHundredDate();
});


const getHundredDate = () => {

	const date = getDateSpacing(new Date(), new Date('2114-02-14'));
	dateObj.value = date;
}

const handleLinkTo = (item) => {
	if (!item?.link) return router.push('/upgrades');
	return router.push(item.link);
	// window.location.href = item.link;
}

</script>

<style lang="scss" scoped>
*ul {
	padding: 0;
	list-style: none;
	margin: 0;
}

*p {
	padding: 0;
	margin: 0;
}

*a {
	margin-top: 0;
}

.imgBox1 {
	img {
		width: 180px;
	}
}

.home-footer {
	color: #fff;
	background: #313438;
	padding-top: 48px;
	padding-bottom: 35px;
	display: flex;
	flex-direction: column;
	align-items: center;
	
	.footer-info {
		width: 1320px;
		display: flex;
		justify-content: space-around;
		align-items: center;

		border-bottom: 1px solid #36393E;
		margin: 0 auto;
		padding-bottom: 38px;

		>.list {
			display: flex;

			>li {
				.default-link{
					color: #F8F9FB;
					font-size: 16px;
					cursor: pointer;
					margin-right: 20px;
				}
				display: flex;
				align-items: center;
				color: #A3A4A6;

				&.first {
					color: #F8F9FB;


					.content {
						display: flex;
						align-items: center;

						>img {
							margin-right: 6px;
						}
					}
				}



				.line {
					margin-right: 20px;
					color: #A3A4A6;
				}
			}
		}

		.footer-left {
			width: 260px;
			border-right: 1px solid hsla(0, 0%, 100%, .1);
			margin-bottom: 35px;

			.consult {
				margin-bottom: 42px;

				>p {
					font-size: 14px;
					margin-bottom: 8px;
					line-height: 20px;
					margin-top: 0;

				}

				>div {
					display: flex;
					align-items: center;
					font-size: 20px;
					line-height: 28px;
					margin-bottom: 8px;
				}

				>span {
					color: hsla(0, 0%, 100%, .5);
					font-size: 14px;
				}
			}

			.cooperation {
				>p {
					font-size: 14px;
					margin-bottom: 12px;
					color: #fff;
				}

				>ul {
					list-style: none;
					padding: 0;

					li {
						display: inline-block;
						white-space: nowrap;
						color: hsla(0, 0%, 100%, .5);
						width: 50%;
						font-size: 14px;
						margin-bottom: 8px;
						cursor: pointer;
					}
				}
			}
		}

		.footer-right {
			display: flex;
			font-size: 14px;

			ul {
				margin-left: 82px;

				li {
					&:nth-child(1) {
						color: #fff;
						margin-bottom: 12px;
					}

					color: hsla(0, 0%, 100%, .5);
					margin-bottom: 8px;
					cursor: pointer;
				}
			}
		}
	}

	.footer-license {
		text-align: center;
		vertical-align: middle;

		.footer-link {
			color: #F8F9FB;
			font-size: 14px;
			text-align: center;
			margin-top: 0px;
			margin: 0 8px;
			font-weight: 400;

			.police {
				width: 12px;
				height: 12px;
				margin-right: 5px;
			}
		}

		>p {
			color: #F8F9FB;
			font-size: 14px;
			text-align: center;
			margin-top: 14px;
			display: flex;
			align-items: center;
			margin-bottom: 2px;
		}

		:deep(.el-link__inner) {
			@extend .footer-link;
			margin: 0 auto;
		}
	}
}
</style>