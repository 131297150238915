<template>
    <div class="wrap" ref="wrap" id="designDetail">
        <div class="main">
            <div class="top">
                <div class="left">
                    <div class="title" v-text="productInfo.name"></div>
                    <div class="other">
                        <span class="other-l">
                            <span>创作于</span>
                            <span v-text="productInfo.createTime"></span>
                        </span>
                        <span class="other-r">
                            {{ productInfo.viewCount }} 预览
                        </span>
                    </div>
                </div>
            </div>
            <div class="brief-info">
                <div class="brief-info-l">
                    <img :src="OSSURL + productInfo.thumbnail" alt="">
                </div>
                <div class="brief-info-r">
                    <div class="top">
                        <div class="top-t">
                            <div class="top-t-l">
                                <img :src="OSSURL + userInfo.avatar" alt="">
                                <div class="info">
                                    <p style="margin-bottom: 10px;" v-text="userInfo.nickName"></p>
                                    <p>ID: {{ userInfo.userName }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="top-b">
                            <div v-for="(item, index) in userTips.tagList" :key="index">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                    <div class="btns">
                        <div class="btn active" v-if="productInfo.btnBuy" style="background-color: #ae3b34;"
                            @click="showBuy">
                            ￥{{ productInfo.price }}购买</div>
                        <div class="btn active" v-else @click="getProgramme">获取方案</div>
                        <div class="btn" @click="showConcat">咨询</div>
                    </div>
                    <div class="middle">
                        <ul>
                            <li>
                                <span class="label">面积：</span>
                                <span class="content">{{ productInfo.area }}㎡</span>
                            </li>
                            <li>
                                <span class="label">价格：</span>
                                <span class="content">￥{{ productInfo.price }}</span>
                            </li>
                            <li>
                                <span class="label">标签：</span>
                                <span class="content">
                                    <span v-for="(item, index) in productInfo.tagNames" :key="index">
                                        <span v-text="item"></span>
                                        <span v-show="index !== productInfo.tagNames.length - 1">、</span>
                                    </span>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="bottom" v-text="productInfo.projDesc"></div>
                </div>
            </div>
            <div class="instance-list">
                <div class="banner slide-image" :class="{ 'fade-out': isFading }">
                    <img :src="currentBanner" alt="" style="width: 100%;">
                </div>
                <ul class="small-list">
                    <li v-for="(item, index) in productInfo.imgList" :key="index" @mouseover="switchBanner(item)">
                        <img :src="OSSURL + item" alt="" srcset="">
                    </li>
                </ul>
            </div>
            <div class="comment-outline">
                <Comment :bizId="+route.params.id" :bizType="Constant.CommentBizProj" :counterLimit="3" width="800px" />
                <div class="comment-outline-right">
                    <div class="title">
                        相关推荐
                    </div>
                    <ul class="product-ul">
                        <li class="product-li" v-for="item in productList" :key="item.id">
                            <div class="top"><img :src="OSSURL + item.thumbnail + ctbImgClipQuery" alt=""
                                    @click="toDetail(item)"></div>
                            <div class="details">
                                <div class="d-header">
                                    <div class="d-title" @click="toDetail(item)">
                                        <span v-if="item.name.length <= 22">{{ item.name }}</span>
                                        <span v-else>{{ item.name.slice(0, 19) }}...</span>
                                    </div>
                                    <div class="sell-type">
                                        <span v-if="item.sellType === Constant.CtbSellFree">免费</span>
                                        <span v-else-if="item.sellType === Constant.CtbSellCash">￥{{ item.price
                                            }}</span>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div class="left">
                                        <img class="avator" :src="OSSURL + item.author.avatar" alt=""
                                            @click="router.push({ path: '/personPage', query: { id: item.author.userId } })">
                                        <span class="author-name" v-text="item.author.nickName"
                                            @click="toAuther(router.resolve({ path: '/personPage', query: { id: item.author.userId } }))"></span>
                                    </div>
                                    <div class="right">
                                        <div>
                                            <img src="@/assets/icons/eye.png" alt="" srcset=""
                                                style="width:12px;margin-right: 3px;">
                                            <span v-text="item.viewNum"></span>
                                        </div>
                                        <div>
                                            <img src="@/assets/icons/comment.png" alt="" srcset=""
                                                style="width:12px;margin-right: 3px;">
                                            <span v-text="item.commentNum"></span>
                                        </div>
                                        <div class="like">
                                            <img src="@/assets/icons/heart.png" alt="" srcset=""
                                                style="width:12px;cursor: pointer;">
                                            <span v-text="item.likeNum"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <el-dialog v-model="payInfo.showDialog" width="600" @closed="closePay">
        <div class="design-pay-info">
            <div class="left">
                <img :src="OSSURL + productInfo.thumbnail" alt="" srcset="" style="width: 200px;">
            </div>
            <div class="right">
                <h1 v-text="productInfo.name"></h1>
                <h2>￥{{ productInfo.price }} </h2>
            </div>
        </div>
        <div class="paymentBox">
            <div class="left">
                <div class="leftText" v-if="!payInfo.qrCodeImg">
                    <p class="text">开通前请阅读</p>
                    <p class="accord" @click="routerToPay"><span style="color: var(--red);">《付费购买协议》</span></p>
                    <div class="btn" @click="agreePay">
                        同意并支付
                    </div>
                </div>
                <div class="left" v-else>
                    <img class="qrImg" :src="payInfo.qrCodeImg" :class="{ activing: payInfo.activing }" alt="">
                    <el-icon class="iconSize" v-if="payInfo.activing" @click="refreshCode">
                        <RefreshRight />
                    </el-icon>
                </div>
            </div>
            <div class="right">
                <div class="top">实付：<span class="money"><span class="icon">￥</span>{{ productInfo.price }}</span></div>
                <div class="bottom">
                    <img class="sellImg" src="@/assets/wx.png" alt="">
                    <img class="sellImg" src="@/assets/zfb.png" alt="">
                    <p class="rightText">扫码支付</p>
                </div>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="payInfo.successDialog" :show-close="true" width="900" :before-close="closeSuccessPay"
        :close-on-click-modal="false">
        <div class="success-box">
            <img src="@/assets/pay.png" alt="">
            <div class="big">
                恭喜您，已支付成功
            </div>
            <div class="small">
                可以在「头像-购买记录」中查看订单详情
            </div>
            <div class="btn">
                <div class="grey" @click="routeToHome">进入工作台</div>
                <div class="red" @click="routeToDesign">开始设计</div>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="concatInfo.show" width="600">
        <div v-for="(item, index) in concatInfo.arr" :key="index">
            <div style="display: flex;align-items:center;color: #fff;margin-bottom: 20px;">
                <span style="margin-right: 10px;">{{ concatInfo[item.way] }}：</span>
                <img v-if="item.way === Constant.CtbContactWechat" :src="OSSURL + item.wayAttach" alt="">
                <span v-if="item.way === Constant.CtbContactPhone" v-text="item.wayVal"></span>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { ctbImgClipQuery } from '@/constant/oss'
import Comment from '@/components/comment/index.vue'
import Constant from '@/constant/constant';
import { OSSURL } from '@/util/file';
import { useRoute, useRouter } from 'vue-router'
import api from '@/api/api'
import { isUserLoggedIn } from '@/router'
import { onMounted, ref, reactive } from 'vue'
import { useStore } from "vuex";
import {
    orderSelect,
    qrCode,
    orderCreate,
} from '@/api/order.js';
import messageBox from '@/constant/message';
const route = useRoute()
const router = useRouter()
const store = useStore()
const productInfo = ref({ imgList: [], tagNames: [] })
const userTips = ref({ tagList: [] })
const userInfo = ref({})
const currentBanner = ref('')
const isFading = ref(false);
const payInfo = reactive({
    showDialog: false,
    successDialog: false,
    qrCodeImg: '',
    activing: false,
    payAmount: ''
})

const concatInfo = reactive({
    arr: [],
    show: false,
    [Constant.CtbContactWechat]: '微信',
    [Constant.CtbContactPhone]: '电话'
})
const productList = ref([])

let timer

async function getUserTips() {
    try {
        const res = await api.userTips({ id: productInfo.value.authorId });
        userTips.value = res;
    } catch (error) {
        console.log(error);
    }
}
async function getUserInfo() {
    try {
        const res = await api.openUserView({ id: productInfo.value.authorId });
        userInfo.value = res;
    } catch (error) {
        console.log(error);
    }
}
async function getDetailData() {
    try {
        const res = await api.productDetail({ id: + route.params.id, userId: store.state.user.userView.id })
        productInfo.value = res
        currentBanner.value = OSSURL + res.imgList[0]
    } catch (error) {
        console.log(error);
    }
}

/**
 * 切换轮播图
 *
 * @param item 轮播图项对象
 */
function switchBanner(item) {
    isFading.value = true;
    setTimeout(() => {
        currentBanner.value = OSSURL + item;
        isFading.value = false;
    }, 300);
}
async function showConcat() {
    try {
        if (!isUserLoggedIn()) {
            handleLogin();
            return;
        }
        if (!productInfo.value.contact) {
            messageBox.error('购买后才能查看联系方式！');
            return
        }
        concatInfo.arr = productInfo.value.contact || []
        concatInfo.show = true
    } catch (error) {
        console.log(error);
    }
}
function routerToPay() {
    window.open('/s/agree-pay', '_blank');
}
function agreePay() {
    let param = {
        orderFrom: Constant.statePc,
        product: [{
            productId: productInfo.value.productId,
            productNum: 1
        }]
    }
    orderCreate(param).then(res => {
        qrCode({ url: process.env.VUE_APP_MOBILE_HOME_PATH + '/qrCode?order=' + res.orderNo }).then(i => {
            payInfo.qrCodeImg = i.img
            timer = setInterval(() => {
                orderSelect({ orderNo: res.orderNo }).then(j => {
                    if (j.orderState != Constant.payOrderStateWait) {
                        payInfo.activing = true
                        if (j.orderState == Constant.payOrderStateSucces || j.orderState != Constant.payOrderStateIng) {
                            if (j.orderState == Constant.payOrderStateSucces) {
                                payInfo.showDialog = false
                                payInfo.successDialog = true
                                getDetailData()
                            }
                            clearInterval(timer)
                        }
                    }
                })
            }, 2000);
        })
    })
}
function closePay() {
    payInfo.qrCodeImg = ''
}
function refreshCode() {
    clearInterval(timer)
    payInfo.activing = false
    agreePay()
}
function closeSuccessPay() {
    payInfo.successDialog = false
}
const routeToHome = () => {
    payInfo.qrCodeImg = null
    payInfo.activing = false
    payInfo.showDialog = false
    payInfo.successDialog = false
    router.push({ path: '/p' }).then(() => {
        window.location.reload();
    })
}
const routeToDesign = () => {
    payInfo.qrCodeImg = null
    payInfo.showDialog = false
    payInfo.successDialog = false
    window.open(process.env.VUE_APP_DESIGN_URL)
}

/**
 * 获取方案
 *
 * @returns 无返回值
 */
async function getProgramme() {
    try {
        if (!isUserLoggedIn()) {
            handleLogin();
            return;
        }
        await messageBox.elMessageBox('获取方案后将重置之前的方案内容，确定要继续吗？')
        await api.openProgramme({
            ctbId: productInfo.value.ctbId,
            op: 'recover'
        })
        router.push({ path: '/p/project/ps' })
    } catch (error) {
        console.log(error);
    }
}
/**
 * 显示购买按钮
 *
 * 此函数用于在用户界面上显示购买按钮。
 */
function showBuy() {
    if (!isUserLoggedIn()) {
        handleLogin();
        return;
    }
    payInfo.showDialog = true
}

async function initRemoteData() {
    try {
        await getDetailData()
        getUserTips();
        getUserInfo();
    } catch (error) {
        console.log(error);
    }
}

async function handleLogin() {
    await store.dispatch('getWeChatSetting');
    store.commit('SET_LOGIN_MODAL_VISIBLE', {
        visible: true,
        type: 'wxLogin',
        path: route.fullPath,
    });
}

async function getProductList() {
    try {
        const params = {
            page: 1,
            size: 2,
            tagList: [],
        }
        const res = await api.productList(params);
        productList.value = res.result || [];
    } catch (error) {
        console.log(error);
    }
}
function toDetail({ ctbId }) {
    const href = router.resolve({ path: `/template/dtl/${ctbId}` }).href
    window.open(href,'_self')  
}
function toAuther(params) {
    window.open(params.href, '_blank');
}
onMounted(() => {
    initRemoteData();
    getProductList();
})

</script>

<style lang="scss" scoped>
.custom-button {
    background-color: #cf3861;
    border: #cf3861;
    color: #ffff;

    &:hover {
        background-color: #cf3861;
        border: #cf3861;
        color: #ffff;
    }
}

img {
    display: inline-block;
}

.wrap {
    width: 100%;
    height: 100%;
    background-color: #161824;

    .main {
        width: 1400px;
        margin: 0 auto;

        >.top {
            padding: 24px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
                color: #fff;

                .title {
                    font-size: 24px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                .other {
                    font-size: 14px;

                    .other-l {
                        margin-right: 10px;
                    }
                }
            }

            .right {
                display: flex;
                gap: 12px;
            }
        }

        .brief-info {
            display: flex;
            justify-content: space-between;
            box-shadow: 0 0 24px 0 hsla(0, 0%, 60%, .08);
            background-color: #2b2e42;
            overflow: hidden;
            margin-bottom: 30px;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;

            .brief-info-l {
                width: 864px;

                >img {
                    width: 100%;
                }
            }

            .brief-info-r {
                width: 516px;
                padding-bottom: 10px;

                >.btns {
                    display: flex;
                    margin-bottom: 20px;

                    >.btn {
                        cursor: pointer;
                        margin-right: 8px;
                        color: #fff;
                        border-radius: 8px;
                        height: 26px;
                        line-height: 26px;
                        text-align: center;
                        padding: 0 10px;
                        background-color: #ffffff;
                        color: #606266;

                        &.active {
                            background-color: #cf3861;
                            color: #fff;
                        }
                    }
                }

                >.top {
                    background-color: #2b2c43;
                    padding-bottom: 10px;
                    margin-bottom: 10px;

                    .top-t {
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        padding-top: 20px;

                        .top-t-l {
                            display: flex;
                            align-items: center;
                            color: #fff;

                            img {
                                width: 64px;
                                height: 64px;
                                border-radius: 50%;
                                margin-right: 12px;
                            }


                        }
                    }

                    .top-b {
                        max-height: 111px;
                        overflow: auto;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;

                        >div {
                            margin-right: 6px;
                            margin-bottom: 6px;
                            border: 1px solid #fff;
                            font-size: 14px;
                            padding: 6px 10px;
                            color: #fff;
                        }
                    }
                }

                .middle {
                    margin-bottom: 20px;

                    >ul {
                        li {
                            font-size: 14px;
                            margin-bottom: 10px;
                            color: #fff;
                            // .label {
                            //     color: #92989b;
                            // }

                            // .content {
                            //     color: #333333;
                            // }
                        }
                    }
                }

                .bottom {
                    width: 460px;
                    border: 1px solid #fff;
                    min-height: 70px;
                    padding: 10px;
                    background-color: #45485f;
                    color: #fff;
                    padding: 12px;
                    font-size: 14px;
                    color: #60656c;
                    color: #fff;
                }
            }
        }

        .instance-list {
            padding-bottom: 30px;

            .slide-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: opacity 1s ease-in-out;
            }

            .slide-image.fade-out {
                opacity: 0;
            }

            .banner {
                width: 100%;
                margin-bottom: 12px;

                img> {
                    width: 100%;
                }
            }

            >.small-list {
                padding: 20px 50px;
                display: flex;
                justify-content: center;
                border-radius: 12px;
                box-shadow: 0 0 24px 0 hsla(0, 0%, 60%, .08);

                li {
                    margin-right: 20px;
                    overflow: hidden;
                    border-radius: 8px;
                    cursor: pointer;
                    width: 180px;
                    box-sizing: border-box;

                    &:hover {
                        border: 1px solid #1a7af8;
                    }

                    >img {
                        width: 100%;
                    }
                }
            }
        }

        .comment-outline {
            display: flex;
            justify-content: space-between;

            >.comment-outline-right {
                width: 500px;

                >.title {
                    display: flex;
                    align-self: flex-start;
                    font-size: 32px;
                    font-weight: 700;
                    color: #fff;
                    margin-bottom: 16px;
                }

                >.product-ul {
                    >.product-li {
                        width: 445px;
                        margin-bottom: 24px;
                        margin-right: 30px;

                        .top {
                            border-radius: 12px 12px 0px 0px;
                            overflow: hidden;

                            >img {
                                width: 100%;
                                cursor: pointer;
                            }
                        }

                        .details {
                            padding: 16px 20px;
                            border-radius: 0 0 12px 12px;

                            background-color: rgb(255, 255, 255);
                            box-shadow: rgba(96, 101, 108, 0.08) 4px 4px 24px 0px;

                            >.d-header {
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 6px;

                                >.d-title {
                                    font-weight: 400;
                                    font-size: 16px;
                                    color: rgb(51, 51, 51);
                                    cursor: pointer;

                                    &:hover {
                                        color: #7bc1ff;
                                    }
                                }

                                >.sell-type {
                                    height: 30px;
                                    line-height: 30px;
                                    background-color: #b13a37;
                                    color: #fff;
                                    text-align: center;
                                    padding: 0 12px;
                                    border-radius: 8px;
                                }
                            }


                            .footer {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .left {
                                    display: flex;
                                    align-items: center;

                                    .avator {
                                        width: 12px;
                                        height: 12px;
                                        border-radius: 50%;
                                        margin-right: 6px;
                                        cursor: pointer;
                                    }

                                    .author-name {
                                        font-size: 12px;
                                        color: rgb(102, 102, 102);
                                        cursor: pointer;

                                        &:hover {
                                            color: #7bc1ff;
                                        }
                                    }
                                }

                                .right {
                                    display: flex;

                                    >div {
                                        font-size: 12px;
                                        margin-right: 8px;
                                        display: flex;
                                        align-items: center;
                                        color: rgb(147, 152, 159);

                                        &.like {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.design-pay-info {
    display: flex;
    margin-bottom: 20px;

    .left {
        margin-right: 10px;
    }

    .right {
        >h1 {
            margin-bottom: 10px;
        }
    }


}

.paymentBox {
    width: 100%;
    height: 160px;
    box-sizing: border-box;
    padding: 25px 20px;
    border: 1px solid #fdfdfd;
    margin-top: 30px;
    border-radius: 12px;
    display: flex;

    .qrImg {
        width: 108px;
        height: 108px;
    }

    .activing {
        filter: blur(5px);
    }

    .iconSize {
        font-size: 70px;
        position: absolute;
        top: calc(50% - 35px);
        left: calc(50% - 35px);
        cursor: pointer;
    }

    .right {
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .top {
            font-size: 20px;
            margin-top: 10px;

            .money {
                color: #AE3B34;
                font-size: 42px;

                .icon {
                    font-size: 29px;
                }
            }
        }

        .bottom {
            .sellImg {
                width: 15px;
                height: 15px;
                display: inline-block;
                margin-left: 5px;
                position: relative;
                top: 2px;
            }

            .rightText {
                display: inline-block;
                font-size: 15px;
                margin-left: 7px;
            }
        }
    }

    .left {
        width: 108px;
        height: 100%;
        background-color: #FBFAFA;
        border-radius: 8px;
        text-align: center;
        position: relative;

        .leftText {
            color: #020202;
            font-size: 10px;
            overflow: hidden;

            .text {
                margin-top: 23px;
            }

            .accord {
                cursor: pointer;
            }

            .btn {
                margin: 0 6px;
                margin-top: 10px;
                background-color: #AE3B34;
                border-radius: 5px;
                height: 18px;
                line-height: 18px;
                font-size: 11px;
                color: #FDFDFD;
                cursor: pointer;
            }
        }
    }
}

.success-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fdfdfd;

    img {
        width: 166px;
        height: 166px;
    }

    .big {
        font-size: 33px;
        margin-top: 40px;
    }

    .small {
        font-size: 22px;
        margin-top: 25px;
    }

    .btn {
        font-size: 23px;
        overflow: hidden;
        margin-top: 55px;

        .grey {
            float: left;
            padding: 5px 20px;
            background-color: #161824;
            border-radius: 10px;
            cursor: pointer;
        }

        .red {
            float: left;
            padding: 5px 20px;
            background-color: #AE3B34;
            border-radius: 10px;
            margin-left: 80px;
            cursor: pointer;
        }
    }
}
</style>