<template>
	<div class="exhibition-comp">
		<div class="zoom-img" v-show="isZoom" @click="() => isZoom = false">
			<img :src="currImage" >
		</div>
		<Instruction title="搭积木式造园" desc="海量素材，快速搭建，超清渲染"/>
		<div class="advantage-list">
			<div class="advantage-item">
				<img src="@/assets/images/advantage1.png" alt="">
				<div>操作简单</div>
				<p>搭积木式设计花园，无需复杂专业技术，普通电脑有网络就可以操作。</p>
			</div>
			<div class="advantage-item">
				<img src="@/assets/images/advantage2.png" alt="">
				<div>渲染快捷</div>
				<p>云端快至30秒完成渲染，一键生成360度全景漫游图，一键分享。</p>
			</div>
			<div class="advantage-item">
				<img src="@/assets/images/advantage3.png" alt="">
				<div>快速设计</div>
				<p>模型材质1秒替换，2D/3D随时切换，一键生成施工图和材料清单。</p>
			</div>
		</div>
		<Instruction title="海量庭院花园素材" desc="免费3d庭院素材，让花园所见即所得"/>
		<div class="treeList">
			<div class="tree" v-for="item in treeData" :key="item.nodeId">
			<!-- <div class="tree" v-for="item in treeData" :key="item.nodeId" @click="() => router.push('/materials')"> -->
				<div class="title">{{item.nodeName}}</div>
				<img :src="OSSURL + item.data" alt="">
			</div>
		</div>
		<!-- <div class="demo-tabs" @click="() => router.push('/materials')">
			<el-button
				:class="`material-btn ${item.nodeId === hoverMaterialsId && 'material-actived'}`"
				type="primary" 
				round 
				v-for="item in modelTree" 
				:name="item.nodeId"
				@mouseover="() => hoverMaterialsId = item.nodeId"
			>{{ item.nodeName }}</el-button>
		</div>

		<swiper
			:ref="swiperRef"
			:modules="modules"
			:slides-per-view="5"
			:space-between="50"
			:speed="2000"
			:loop="true"
			:autoplay="{
                delay: 500,
                disableOnInteraction: false,
                stopOnLastSlide: false,
                pauseOnMouseEnter: false,
            }" 
		>
			<swiper-slide style="cursor: pointer;" v-for="(item, index) in imagesList" :key="index"><img class="material-img" :src="item" alt="" @click="handleClickImage(item)"></swiper-slide>
		</swiper> -->
		<Instruction title="更快捷 更高效" desc="助力企业老板、花园设计师、业务员、快速出设计、轻松签单"/>
		<swiper
			:ref="swiperRef2"
			:modules="modules"
			:slides-per-view="3"
			effect='coverflow'
			:space-between="50"
			:speed="2000"
			:loop="true"
			:coverflowEffect="{
				rotate: 0,
				scale: 0.5,
				stretch: 0, // 幻灯片之间的间距
				depth: 100, // 幻灯片的深度
				modifier: 1, // 3D 视差倍率
				slideShadows: true, // 是否显示幻灯片的阴影
			}"
			:autoplay="{
				delay: 1000,
				disableOnInteraction: false,
				stopOnLastSlide: false,
				pauseOnMouseEnter: false,
			}" 
		>
			<swiper-slide v-for="(item, index) in teachingImages" :key="index"><img class="material-img" :src="item" alt=""></swiper-slide>
		</swiper>

<!--		<Instruction title="数百万企业青睐" desc="图布斯十年风雨，花园设计和您共同成长"/>-->
<!--		<div class="slide-in">-->
<!--			<div class="slide-box1">-->
<!--				<img v-for="item in partnerList.slice(0, 17)" :src="item" alt="">-->
<!--			</div>-->
<!--			<div class="slide-box2">-->
<!--				<img v-for="item in partnerList.slice(17, 34)" :src="item" alt="">-->
<!--			</div>-->
<!--			<div class="slide-box3">-->
<!--				<img v-for="item in partnerList.slice(34, 50)" :src="item" alt="">-->
<!--			</div>-->
<!--		</div>-->
		<!-- <Instruction title="最新资讯" desc="了解行业动态，关注花园未来"/>
		<div>
			<swiper
				:ref="swiperRef2"
				:modules="modules"
				:slides-per-view="3"
    			effect='coverflow'
				:space-between="50"
				:speed="2000"
				:loop="true"
				:coverflowEffect="{
					rotate: 0,
					scale: 0.5,
					stretch: 0, // 幻灯片之间的间距
					depth: 100, // 幻灯片的深度
					modifier: 1, // 3D 视差倍率
					slideShadows: true, // 是否显示幻灯片的阴影
				}"
				:autoplay="{
					delay: 100,
					disableOnInteraction: false,
					stopOnLastSlide: false,
					pauseOnMouseEnter: false,
				}" 
			>
				<swiper-slide v-for="index in 5" :key="index"><img class="material-img" src="@/assets/images/news/2-24061114110OJ.jpg" alt=""></swiper-slide>
			</swiper>
		</div> -->
		<Instruction title="做花园，用图布斯" desc="因为专业，所以强大" :overStyle="true"/>
		<div class="quantity-wrap">
			<div class="bg-map"></div>
<!--			<div class="quantity">-->
<!--				<div class="wrap">-->
<!--					38<span>万+</span>-->
<!--					<div class="spacing"></div>-->
<!--					<div class="msg">设计师正在使用</div>-->
<!--				</div>-->
<!--				<div class="wrap">-->
<!--					79<span>所</span>-->
<!--					<div class="spacing"></div>-->
<!--					<div class="msg">覆盖院校</div>-->
<!--				</div>-->
<!--				<div class="wrap">-->
<!--					3200<span>家</span>-->
<!--					<div class="spacing"></div>-->
<!--					<div class="msg">品牌合作企业</div>-->
<!--				</div>-->
<!--			</div>-->
		</div>
		
	</div>
</template>
	
<script setup>
import Constant from '@/constant/constant';
import { onMounted,ref, computed} from 'vue';
import Instruction from './instruction.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Scrollbar, A11y, Autoplay, EffectCoverflow} from 'swiper/modules';
import {getTreeApi, getModelImageApi} from '@/api/login';
import {partnerList} from '@/constant/oss';
import {useStore} from "vuex";
import { useRouter } from 'vue-router';
const router = useRouter();
const store = useStore()


const isZoom = ref(false);
const imagesList = ref([]);
const modules = [ Navigation, Autoplay, EffectCoverflow];
const swiperRef = ref(null);
const swiperRef2 = ref(null);
const currImage = ref('');
const modelTree = ref();
const hoverMaterialsId = ref('10');
const treeData = ref([])



import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/effect-coverflow';
import { OSSURL } from '@/util/file';


onMounted(()=>{
	getModelImage();
	getTreeData();
});

getTreeApi({bizCode:Constant.TreeModel,nodeId:'0',state:1,}).then((resp) => {
	let result = resp.result.children
	treeData.value = result.filter(res => res.state == 1)
})
const partnerImages = computed(() => {
    const imageContext = require.context('@/assets/images/partner', false, /\.(png)$/);
    return imageContext.keys().map(key => imageContext(key));
})

const teachingImages = computed(() => {
	const imageContext = require.context('@/assets/images/teaching', false, /\.(png)$/);
    return imageContext.keys().map(key => imageContext(key));
})
const checkLogin = () => {
	const isLogin = window.localStorage.getItem(Constant.HttpAccessToken);
	if(!isLogin) {
		store.commit('SET_LOGIN_MODAL_VISIBLE', {
			visible: true,
			type: 'wxLogin',
			path: '',
		});
		return false;
	}
	return true
}
const handleClickImage = (image) => {
	if(!checkLogin()) {
		return;
	}
	isZoom.value = true;
	currImage.value = image;
}
const getTreeData = () => {
	const params = {
		bizCode: 'model_tree',
		nodeId: '0'
	}
	getTreeApi(params).then(res => {
		modelTree.value = res.result.children?.filter(res => {
			return res.state === 1
		})
	}).catch((err) => {
		
	});
}

const getModelImage = () => {
	const params = {
		categoryList: [],
		isHome: 1,
		page: 1,
		size: 20
	}
	getModelImageApi(params).then(res => {
		imagesList.value = res?.result?.map(item=>process.env.VUE_APP_OSSURL + item.preview) || [];
	}).catch((err) => {
		
	});
}

</script>
	
<style lang="scss" scoped>
$theme-color: #cf3861;
.treeList {
	display: flex;
	justify-content: space-around;
	color: #fdfdfd;
	.tree {
		cursor: pointer;
		background-color: rgba(255, 255, 255, 0.2509803922);
		border-radius: 10px;
		padding: 10px;
		.title {
			text-align: center;
		}
		img {
			width: 180px;
			height: 180px;
		}
	}

}
.swiper-slide {
	display: flex;
	justify-content: center;
}
.exhibition-comp {
	background: #2E3136;
	padding: 60px 0;
	.advantage-list {
		display: flex;
		margin: 0 5%;
		justify-content: space-between;
		.advantage-item {
			overflow: hidden;
			border-radius: 16px;
			height: 240px;
			position: relative;
			background: transparent;
			flex: 1;
			margin: 0 12px;
			&:hover img {
				transform: scale(1.1);
			}
			>img {
				display: block;
				height: 100%;
				width: 100%;
    			overflow: clip;
				transition: transform 1s ease;
			}
			&:after {
				opacity: 1;
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				background: rgba(29, 33, 41, .6);
				z-index: 2;
			}
			>div {
				width: 105px;
				height: 24px;
				color: #fff;
				font-size: 24px;
				line-height: 22px;
				cursor: pointer;
				-webkit-transition: right .5s, opacity .2s;
				transition: right .5s, opacity .2s;
				position: absolute;
				left: 38%;
				top: 30%;
				z-index: 3;
			}
			> p {
				position: absolute;
				bottom: 60px;
				left: 20%;
				box-sizing: border-box;
				transition: opacity .2s;
				z-index: 3;
				font-size: 14px;
				color: #FFF;
				line-height: 20px;
			}
		}
	}
}
.swiper-wrapper {
  display: flex; /* 使用 flex 布局 */
  transition: transform 0.5s ease; /* 添加 transform 过渡效果 */
}
.material-img {
	width: auto;
	height: 268px;
	background: #a8a8a8;
	border-radius: 14px;
}
.quantity-wrap {
	position: relative;
	width: 1344px;
	margin: 68px auto 0;
	.bg-map {
		height: 560px;
		background-image: url('@/assets/images/map.png');
		background-size: cover;
		opacity: 0.2;
		position: relative;
	}
	.quantity {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		.wrap {
			flex: 1;
			color: #fff;
			// background: #ffffff40;
			border-radius: 10px;
			padding: 20px 0;
			z-index: 9;
			padding: 20px 0;
			font-size: 42px;
			line-height: 80px;
			background: #ffffff40;
			text-align: center;
			>span {
				font-size: 20px;
			}
			.spacing {
				width: 47px;
				height: 4px;
				background-color: #cf3861;
				margin: 0 auto;
			}
			.msg {
				font-size: 18px;
    			line-height: 40px;
			}
		}
	}
}

.zoom-img {
	width: 100vw;
  	height: 100vh;
	position: fixed;
	top: 0;
	background: rgba(0,0,0,.6);
	z-index: 4;
	img {
		width: 60%;
		max-height: 70%;
		position:absolute;
		top:0;
		bottom:0;
		left:0;
		right: 0;
		margin: auto;
		z-index: 4;
		background: transparent;
	}
}

.slide-in {
    position: relative;
    width: 100%; /* 图片容器宽度 */
    height: 400px; /* 图片容器高度 */
    overflow: hidden; /* 溢出部分隐藏 */
  }
  
  	.slide-box1{
		position: absolute;
		top: 0;
		left: 0;
		// right: -100%; /* 初始位置在左侧屏幕外 */
		animation: slideRight 100s linear infinite; /* 滑动动画 */
		// width: 100%; /* 图片宽度占满容器 */
		display: flex;
		flex-wrap: nowrap;
		> img {
			margin: 0 15px;
		}
	}
	.slide-box2 {
		@extend .slide-box1;
		margin-top: 130px;
		padding-left: 80px;
	}	
	.slide-box3 {
		@extend .slide-box1;
		margin-top: 260px;
	}	
  
	@keyframes slideRight {
		0% {
			left: 0; /* 初始位置在左侧屏幕外 */
		}
		100% {
			left: -140%; /* 最终位置在右侧屏幕外 */
		}
	}

.material-btn {
	
	margin: 0 20px;
	height: 28px;
	border-radius: 14px;
	background: #454950;
	border: #454950;
	color: #C9CDD4;
	width: 85px;
	text-align: center;
	&:hover {
		background: #e84b76;
	}
}
.material-actived {
	background: #e84b76;
	border: #e84b76;
}

.demo-tabs {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 40px;
}
:deep(.el-tabs__item) {
	color: #fff;
	&:hover {
		color: $theme-color;
	}
}
:deep(.el-tabs__item.is-active){
  color: $theme-color;
}

:deep(.el-tabs__active-bar) {
	background-color: $theme-color;
}

</style>