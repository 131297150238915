export const productLinks = [
  [
    { name: "产品与服务", link: "" },
    { name: "3D云设计", link: "" },
    { name: "优秀案例", link: "" },
    { name: "模型库", link: "" },
    { name: "花园百科", link: "" },
    { name: "学习中心", link: "" },
    { name: "找设计师", link: "" },
  ],
  [
    { name: "热门模板", link: "" },
    { name: "新中式花园模板", link: "" },
    { name: "现代花园模板", link: "" },
    { name: "禅意花园模板", link: "" },
    { name: "田园花园模板", link: "" },
    { name: "露营花园模板", link: "" },
  ],
  [
    { name: "下载", link: "" },
    { name: "图布斯客户端", link: "" },
    { name: "图布斯设计师APP", link: "" },
    { name: "图布斯小程序", link: "" },
  ],
  [
    { name: "其他", link: "" },
    { name: "用户服务协议", link: "/agreement" },
    { name: "隐私政策", link: "/privacy" },
    { name: "举报", link: "" },
  ],
  [
    { name: "关于我们", link: "" },
    { name: "新闻中心", link: "" },
    { name: "集团公益", link: "" },
    { name: "图布斯·红心奖", link: "" },
    { name: "加入我们", link: "" },
    { name: "帮助文档", link: "" },
    { name: "安全和合规", link: "" },
  ],
];

export const primaryClassification = [
  "硬装",
  "软装",
  "植物",
  "景观鱼池",
  "亮化灯具",
  "花园构筑物",
];
export const secondaryClassification = [
  "全部",
  "亭子",
  "地面",
  "地拼组合",
  "景墙",
  "围栏",
  "围边",
  "花园门",
  "景观石",
];
export const footerList = [
  // {
  //   name: "优秀设计",
  //   link: "/f/design",
  // },
  {
    name: "模板中心",
    link: "/template",
  },
  {
    name: "关于我们",
    link: "/about",
  },
  {
    name: "学习中心",
    link: "/study",
  },
  {
    name: "帮助中心",
    link: "/f",
  },
  {
    name: "用户服务协议",
    link: "/s/agreement",
  },
  {
    name: "隐私政策",
    link: "/s/privacy",
  },
];
